import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import HeroWithoutImage from "../components/Hero/HeroWithoutImage"
import Paginator from "../components/Paginator"
import BlogCategories from "../components/Blog/BlogCategories"
import BlogList from "../components/Blog/BlogList"
import SEO from "../components/seo"
import { withPreview } from "gatsby-source-prismic"
import { Date } from "prismic-reactjs"

const BlogListView = props => {
  const posts = props.data.allPrismicBlogEntry.edges
  const postNodes = posts.sort(
    (a, b) =>
      new Date(b.node.data.publish_date).getTime() - new Date(a.node.data.publish_date).getTime(),
  )

  return (
    <Layout>
      <SEO title="Blog" />
      <HeroWithoutImage title={"Blog"} />
      <BlogCategories categories={props.data.allPrismicBlogCategories.edges.map(c => c.node)} />
      <section className="uk-section uk-section-xsmall">
        <div className="uk-container">
          <BlogList posts={postNodes} />
        </div>
      </section>
      <Paginator context={props.pageContext} path={"/blog"} />
    </Layout>
  )
}

export default withPreview(BlogListView)

export const blogListQuery = graphql`
  query blogListQuery($skip: Int!, $limit: Int!) {
    allPrismicBlogEntry(
      sort: { fields: data___publish_date, order: DESC }
      limit: $limit
      skip: $skip
    ) {
      edges {
        node {
          uid
          data {
            publish_date
            headline {
              html
              text
            }
            author {
              document {
                ... on PrismicAuthors {
                  uid
                  data {
                    author_name
                  }
                }
              }
            }
            categories {
              blog_tags {
                uid
                document {
                  ... on PrismicBlogCategories {
                    data {
                      label
                    }
                  }
                }
              }
            }
            tile_image_for_blog_list__required_ {
              url
              fixed(width: 500) {
                ...GatsbyPrismicImageFixed_noBase64
              }
            }
          }
        }
      }
    }
    allPrismicBlogCategories {
      edges {
        node {
          data {
            label
          }
          uid
        }
      }
    }
  }
`
